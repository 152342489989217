import './assets/styles/colors.css';
import './assets/styles/typography.css';
import './assets/styles/main.css';
import './assets/styles/desktop.css';
import React, {useEffect} from 'react';
import {initTranslations} from './services/translations';

import {Route, Routes} from 'react-router';
import {CalendarItemPage} from './routes/CalendarItemPage';
import HealthcarePartyPage from './routes/HealthcarePartyPage';
import PrivacyPage from './routes/PrivacyPage';
import TermsOfUsePage from './routes/TermsOfUse';
import {BrowserRouter} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import ErrorScreen from './components/ErrorScreen';
import * as Fathom from 'fathom-client';

// Polyfill to add smooth scrolling in Safari
import smoothscroll from 'smoothscroll-polyfill';
import {FATHOM_SITE_ID} from './constants/fathom.constants';
import PracticePage from './routes/PracticePage';
import FAQPage from './routes/FAQ';

//import { createClient, get, getAll } from '@vercel/edge-config';

smoothscroll.polyfill();

initTranslations();

function App() {

  useEffect(()=>{
    Fathom.load(FATHOM_SITE_ID)
    //loadConfig()

  },[])

  /*
  async function loadConfig() {
    const firstConfig = createClient(process.env.REACT_APP_EDGE_CONFIG);
    const items = await firstConfig.get('greeting');
   console.log(items )
  }
*/

  return (
    <React.Suspense fallback="Loading...">
      <BrowserRouter>
        <div className="app">
          <Header />
          <Routes>
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsOfUsePage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path=":groupId/:healthcarePartyId/:calendarItemId/:login/:token" element={<CalendarItemPage />} />
            <Route path=":groupId/:healthcarePartyId" element={<HealthcarePartyPage />} />
            <Route path=":groupId" element={<PracticePage />} />

            <Route path="*" element={<ErrorScreen message="There's nothing here !" />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
